import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["element-loading-spinner"]
const _hoisted_2 = { class: "admin-dashboard-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_dashboard_layer_1 = _resolveComponent("vc-dashboard-layer-1")!
  const _component_vc_dashboard_overview_optimize = _resolveComponent("vc-dashboard-overview-optimize")!
  const _component_vc_dashboard_underwriter_overview = _resolveComponent("vc-dashboard-underwriter-overview")!
  const _component_vc_dashboard_accounts_overview = _resolveComponent("vc-dashboard-accounts-overview")!
  const _component_vc_dashboard_marketing_overview = _resolveComponent("vc-dashboard-marketing-overview")!
  const _component_vc_dashboard_sales_overview = _resolveComponent("vc-dashboard-sales-overview")!
  const _component_vc_dashboard_sales_person_info = _resolveComponent("vc-dashboard-sales-person-info")!
  const _component_vc_dashboard_statistics = _resolveComponent("vc-dashboard-statistics")!
  const _component_vc_dashboard_others = _resolveComponent("vc-dashboard-others")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    "element-loading-background": "rgba(122, 122, 122, 0.1)",
    "element-loading-text": "Please wait",
    "element-loading-spinner": _ctx.svg,
    "element-loading-svg-view-box": "-10, -10, 50, 50",
    style: {"z-index":"1"}
  }, [
    _createVNode(_component_vc_dashboard_layer_1, {
      onGetData: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getData()))
    }),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.filter.tab == 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            ([1, 14].includes(_ctx.user.role_id))
              ? (_openBlock(), _createBlock(_component_vc_dashboard_overview_optimize, { key: 0 }))
              : (_ctx.user.role_id == 4)
                ? (_openBlock(), _createBlock(_component_vc_dashboard_underwriter_overview, {
                    key: 1,
                    "user-role-id": _ctx.user.role_id
                  }, null, 8, ["user-role-id"]))
                : (_ctx.user.role_id == 6)
                  ? (_openBlock(), _createBlock(_component_vc_dashboard_accounts_overview, {
                      key: 2,
                      "user-role-id": _ctx.user.role_id
                    }, null, 8, ["user-role-id"]))
                  : ([11, 7].includes(_ctx.user.role_id))
                    ? (_openBlock(), _createBlock(_component_vc_dashboard_marketing_overview, {
                        key: 3,
                        "user-role-id": _ctx.user.role_id
                      }, null, 8, ["user-role-id"]))
                    : (_openBlock(), _createBlock(_component_vc_dashboard_sales_overview, {
                        key: 4,
                        "user-role-id": _ctx.user.role_id
                      }, null, 8, ["user-role-id"]))
          ], 64))
        : (_ctx.filter.tab == 1)
          ? (_openBlock(), _createBlock(_component_vc_dashboard_sales_person_info, { key: 1 }))
          : (_ctx.filter.tab == 2)
            ? (_openBlock(), _createBlock(_component_vc_dashboard_statistics, { key: 2 }))
            : (_ctx.filter.tab == 3)
              ? (_openBlock(), _createBlock(_component_vc_dashboard_others, { key: 3 }))
              : _createCommentVNode("", true)
    ])
  ], 8, _hoisted_1)), [
    [_directive_loading, _ctx.loading]
  ])
}