
import { defineComponent, onMounted, computed, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from "@/router/clean";
import moment from "moment";
import { store } from "@/store";
import Dashboard from "@/core/services/Callable/Dashboard";

export default defineComponent({
  name: "dashboard",
  setup() {
    const loading = ref(false);

    const user = computed(() => {
      const users = window.localStorage.getItem("user");
      const user = users ? JSON.parse(users || "") : "";
      console.log("user", user);
      return user;
    });

    const filter = computed(() => {
      return store.state.dashboard.filter;
    });

    const title = computed(() => {
      let header = "Dashboard";
      if (user.value) {
        switch (user.value.role_id) {
          case 2:
            header = "Sales Agent Dashboard";
            break;
          case 9:
            header = "Sales Manager Dashboard";
            break;
          case 13:
            header = "Team Leader Dashboard";
            break;
          case 4:
            header = "Underwriter Dashboard";
            break;
          case 6:
            header = "Accountants Dashboard";
            break;
          case 7:
          case 11:
            header = "Marketing Dashboard";
            break;
          default:
            header = "Dashboard";
            break;
        }

        // if([2, 4, 9, 13].includes(user.value.role_id)) {
        header = `${header}&nbsp;&nbsp;|&nbsp;&nbsp;Summary&nbsp;<span style="font-weight: 400">(@SUMMARY@)</span>`;
        // }
      }

      return header;
    });

    onMounted(async () => {
      setCurrentPageBreadcrumbs(title.value, []);
      const date = new Date();
      filter.value.tab = 0;
      filter.value.start_date = moment(date).format("YYYY-MM-DD");
      filter.value.end_date = moment(date).format("YYYY-MM-DD");

      if (user.value && [2, 4, 6, 9, 11, 13].includes(user.value.role_id)) {
        filter.value.type = "month";
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        filter.value.start_date = moment(firstDay).format("YYYY-MM-DD");
        filter.value.end_date = moment(lastDay).format("YYYY-MM-DD");
        if (user.value.role_id == 4) await getUnderwriterDashboardInfo();
        if (user.value.role_id == 6) await getUAccountsDashboardInfo();
        if ([11, 7].includes(user.value.role_id))
          await getUMarketingDashboardInfo();
        else await getDashboardSalesPersonInfo();
      } else getData();
    });

    const dashboardUrls = [
      "total-sales",
      "total-new-sales",
      "total-renewal-sales",
      "total-llr-sales",
      "total-lrl-sales",
      "lifetime-renewal-leads",
      "total-untouched-leads",
      // 'total-refund',
      // 'total-revenue',
      "total-wip-sales",
    ];

    const getDashboardOverview = async () => {
      // for(const url of dashboardUrls) {
      // filter.value.url = url
      filter.value.urls = dashboardUrls;
      loading.value = true;
      await Dashboard.getDashboardOverview(filter.value);
      loading.value = false;
      // }
    };

    const getDashboardSalesPersonInfo = async () => {
      loading.value = true;
      await Dashboard.getDashboardSalesPersonInfo(filter.value);
      loading.value = false;
    };

    const getUnderwriterDashboardInfo = async () => {
      loading.value = true;
      await Dashboard.getUnderwriterDashboard(filter.value);
      loading.value = false;
    };

    const getUAccountsDashboardInfo = async () => {
      loading.value = true;
      await Dashboard.getAccountsDashboard(filter.value);
      loading.value = false;
    };

    const getUMarketingDashboardInfo = async () => {
      loading.value = true;
      await Dashboard.getMarketingDashboard(filter.value);
      loading.value = false;
    };

    const getData = async () => {
      console.log("Dasacalling...");

      if (user.value && [2, 9, 13].includes(user.value.role_id))
        await getDashboardSalesPersonInfo();
      else if (user.value.role_id == 4) await getUnderwriterDashboardInfo();
      else if (user.value.role_id == 6) await getUAccountsDashboardInfo();
      else if ([11, 7].includes(user.value.role_id))
        await getUMarketingDashboardInfo();
      else {
        if (filter.value.tab == 0) getDashboardOverview();
        else if (filter.value.tab == 1) await getDashboardSalesPersonInfo();
        else if (filter.value.tab == 2){
          loading.value = true;
          await Dashboard.getDashboardStatistics(filter.value);
          loading.value = false;
        }
        else if (filter.value.tab == 3){
          loading.value = true;
          await Dashboard.getDashboardOthers(filter.value);
          loading.value = false;
        }
      }

    };

    return {
      user,
      filter,
      getData,
      loading,
    };
  },
});
